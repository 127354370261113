<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Cihaz Geçmişi</div>
      </div>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="font-medium text-uppercase mb-0">Ara</label>
              <input
                type="text"
                class="form-control"
                placeholder="bulmak istediğiniz cihaz bilgilerini yazın"
              />
            </div>
          </div>
          <div class="col-sm-8">
            <div class="d-flex align-items-center">
              <div class="form-group">
                <div class="demo-inline-spacing">
                  <b-form-radio name="some-radios" value="A">
                    Cihaz'dan
                  </b-form-radio>
                  <b-form-radio name="some-radios" value="B">
                    Müşteri'den
                  </b-form-radio>
                </div>
              </div>
              <div class="form-group mt-1">
                <button class="btn btn-sm btn-primary">Sorgula</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>